@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --gold-yellow: #ffb010;
    --off-white: #eee;
    --off-black: #1a1a1a;
    --link-blue: #1570ef;
    --error-red: #fc3535;
    --success-green: #2fc222;
}

html,
[data-name='nextstep-wrapper'],
[data-name='nextstep-site'] {
    height: 100%;
    min-height: 100vh;
}

body {
    height: 100%;
    min-height: 100%;
    height: 100%;
}

*:focus:not(:focus-visible),
*[data-focused] {
    outline: 0 !important;
}

.link.link-blue {
    color: var(--link-blue) !important;
}

.link {
    color: var(--off-black);
}

[data-theme='dark'] {
    color: var(--off-white);

    .bg-primary {
        color: var(--off-black);
    }

    .link.link-blue {
        color: color-mix(in srgb, var(--link-blue) 70%, white) !important;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #222 !important;
}

.embla {
    overflow: hidden;
}
.embla__container {
    display: flex;
}
.embla__slide {
    flex: 0 0 100%;
    min-width: 0;
    margin-right: 1rem;
}

.react-aria-ModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: var(--visual-viewport-height);
    background: rgba(0 0 0 / 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;

    &[data-entering] {
        animation: modal-fade 200ms;
    }

    &[data-exiting] {
        animation: modal-fade 150ms reverse ease-in;
    }
}

.react-aria-Modal {
    box-shadow: 0 8px 20px rgba(0 0 0 / 0.1);
    border-radius: 6px;
    background: var(--overlay-background);
    color: var(--text-color);
    border: 1px solid var(--gray-400);
    outline: none;
    width: max-content;

    &[data-entering] {
        animation: modal-zoom 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    .react-aria-TextField {
        margin-bottom: 8px;
    }
}

.recharts-layer circle:hover {
    cursor: pointer;
}

lite-youtube {
    max-width: none !important;
}

@keyframes modal-fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes modal-zoom {
    from {
        transform: scale(0.8);
    }

    to {
        transform: scale(1);
    }
}
